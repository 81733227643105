@import "../../assets/styles/utils";
header {
    width: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    box-sizing: border-box;
    transition: all .5s;
    box-shadow:0 5px 10px -5px rgba(0,0,0,.3);
    @include res(height,$header-height-base, $header-height-ratio);
    @include res(padding-left,65px, 10 / 65);
    @include res(padding-right, 65px, 10 / 65);
    &.hide{
        transform: translateY(-100%);
    }
    &.head1 {
        @include res(height, 90px, 70 / 90);
        .nav {
            @include res(top, 90px, 70 / 90);
        }
    }
    .left {
        display: flex;
        a {
            @include res(font-size, 20px, 16 / 20);
            color: rgba(0,0,0,.8);
            @include res(margin-right, 60px, 10 / 60);
            transition: all .3s;
            &:hover {
                color: #A89CBB;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .right {
        display: flex;
        align-items: center;
        .img{
            img{
                display: block;
                width: auto;
                @include res(height, 28px,(xs:17px));
            }
        }
        a {
            transition: all .3s;
            @include res(font-size, 16px, 14 / 16);
            color: #484848;
            i {
                font-size: 22px;
                color: #000;
                transition: all .3s;
                &:hover {
                    color: #A89CBB;
                }
            }
            &:not(:last-child){
                @include res(margin-right, 25px, 5 / 25);
            }
            &:hover {
                color: #A89CBB;
            }
        }
    }

    .nav {
        @include res(padding-top, 50px,(xs:15px));
        @include res(padding-bottom, 30px,(xs:10px));
        position: fixed;
        @include res(top, 105px, 70 / 105);
        width: 100%;
        left: 0;
        @include res(height, fit-content);
        background: #F1F1F1;
        box-sizing: border-box;
        @include res(padding-left, 125px, 10 / 125);
        @include res(padding-right, 110px, 10 / 110);
        display: flex;
        flex-wrap: wrap;
        li {
            @include res(width, 158px,(sm:33%));
            @include res(margin-right, 60px, (mmd:40px,md:20px,sm:0));
            @include res(margin-bottom, 20px, 15 / 20);
            >a {
                margin: auto;
                display: block;
                @include res(width, 158px,(sm:100px,xs:100px));
                @include res(height, 182px,(sm:115px,xs:110px));
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url(./images/nav-bg.png);
                @include res(background-size,cover,(xs: 92% auto));
                img {
                    max-width: fit-content;
                    transition: all .3s;
                    @include res(height,auto,(sm:70px,xs:50px));
                    @include res(width,100%,(sm:auto));
                }
            }
            h3 {
                text-align: center;
                color: #000000;
                transition: all .3s;
                @include res(margin-top, 20px, 10 / 20);
                a {
                    display: block;
                    width: 100%;
                    height: fit-content;
                    @include res(font-size, 18px, 14 / 18);
                }
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
                h3 {
                    color: #A89CBB;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        img {
            max-width: fit-content;
            display: block;
            margin: 0 auto;
            @include res(width,100%,(sm:70%,xs:65%));
        }
    }
}