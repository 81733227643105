@font-face {
  font-family: "iconfont"; /* Project id 3581306 */
  src: url('iconfont.woff2?t=1660618364209') format('woff2'),
       url('iconfont.woff?t=1660618364209') format('woff'),
       url('iconfont.ttf?t=1660618364209') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Singapore:before {
  content: "\e62d";
}

.icon-youjiantou1:before {
  content: "\e733";
}

.icon-zuojiantou1:before {
  content: "\e734";
}

.icon-email:before {
  content: "\e69f";
}

.icon-gouwu:before {
  content: "\e7b1";
}

.icon-shanghai:before {
  content: "\e605";
}

.icon-twitter:before {
  content: "\e882";
}

.icon-Web:before {
  content: "\e610";
}

.icon-facebook:before {
  content: "\e641";
}

