@import "./utils";

header .left a{
  @include res(font-size, 16px, 14 / 16);
}
header .right a{
  @include res(font-size, 15px, 14 / 15);
}
header .nav li h3 a{
  @include res(font-size, 16px, 14 / 16);
}
header .logo img{
  @include res(width,90%,(sm:70%));
}

footer .top .left ul li .text h3{
  @include res(font-size,16px, 14 / 16);
}
footer .top .left ul li .text p{
  @include res(font-size, 15px, 14 / 15);
}
footer .top .right li a{
  @include res(font-size,18px, 16 / 18);
}
footer .top .right li a i{
  @include res(font-size,26px, 20 / 26);
}
footer .center .left h3{
  margin-bottom: 10px;
}
footer .center .left p{
  @include res(font-size, 15px, 14 / 15);
}

.list-shop .list ul li .item-title{
  @include res(font-size,16px, 14 / 16);
}

.page_about_contact{
  .contact .cont .left-cont .cont2{
    @include res(font-size,42px, 22 / 42);
  }
  .contact .cont .left-cont .cont3 h1{
    @include res(font-size,16px, 14 / 16);
  }
  .contact .cont .left-cont .cont3 .form .form-item input, .contact .cont .left-cont .cont3 .form .form-item textarea{
    font-size: 14px;
  }
  .contact .cont .left-cont .cont3 .form .form-item button{
    padding: 8px 26px;
    @include res(font-size,14px, 14 / 14);
  }
}

.page_about_efficacious{
  .efficacious .left-text h1{
    @include res(font-size,16px, 14 / 16);
  }
  .efficacious .left-text .desc{
    @include res(line-height,1.5);
    @include res(font-size,55px, 22 / 55);
  }
  .efficacious .right-list ul li h3{
    @include res(font-size,16px, 16 / 16);
  }
}

.page_about_mission{
  .section1 .cont .right-text .title{
    @include res(font-size,30px, 18 / 30);
  }
  .section1 .cont .right-text .desc{
    @include res(font-size,18px, 14 / 18);
  }
  .section2 h1{
    @include res(font-size,42px, 22 / 42);
  }
  .section2 .desc{
    @include res(font-size,16px, 14 / 16);
  }
}

.page_about_offline{
  .offline .cont .left h2{
    @include res(font-size,24px, 16/ 24);
  }
  .offline .cont.cont1 .left .desc p{
    @include res(font-size,16px, 14/ 16);
  }
  .offline .cont.cont2 .left .desc p{
    @include res(font-size,16px, 14/ 16);
  }
  .offline .cont.cont2 .left .desc p span{
    @include res(font-size,14px, 14/ 14);
  }
}

.page_about_online{

}

.page_about_reviews{
  .section2 .section2-title{
    @include res(font-size,18px, 16 / 18);
  }
}

.page_about_story{
  .story .container .cont .desc{
    @include res(font-size,42px, 22 / 42);
  }
  .story .container .cont .text .left, .story .container .cont .text .right{
    @include res(font-size,16px, 14 / 16);
    @include res(width,45%,(xs:100%));
  }
  .story .container .cont .text .right .t2{
    @include res(font-size,18px, 16 / 18);
  }
}

.page_about_sustainability{
  .section1 .container .t1{
    @include res(font-size, 16px, 14 / 16);
    @include res(margin-bottom, 0px, 20 / 0);
  }
  .section1 .container .t2 .t{
    @include res(font-size, 16px, 14 / 16);
  }
  .section2{
    color: $color-text;
  }
  .section2 .section2_lefts .title{
    @include res(font-size, 24px, 16 / 24);
  }
  .section2 .t{
    @include res(font-size, 16px, 14 / 16);
  }
  .section3{
    @include res(padding-top, 20px, 10 / 20);
    @include res(padding-bottom, 20px, 10 / 20);
  }
  .section3 .cont .right .desc{
    color: #fff;
    @include res(margin-bottom, 20px, 10 / 20);
  }
  .section3 .cont .right .title{
    color: #fff;
    @include res(font-size, 24px, 16 / 24);
  }
}

.page_article_shop{
  .section1 .shop-detail .right h1{
    @include res(font-size,32px, 20 / 32);
  }
  .section1 .shop-detail .right .detail .item h2{
    @include res(font-size,16px, 16 / 16);
  }
  .section1 .shop-detail .right .detail .item .desc{
    @include res(font-size,16px, 14 / 16);
  }
  .section3 .title{
    @include res(font-size,26px, 18 / 26);
  }
}

.page_index{
  .banner #banner-swiper .swiper-slide .title h2{
    @include res(font-size,42px ,18 / 42);
  }
  .banner #banner-swiper .swiper-slide .title p{
    @include res(font-size, 18px, 14 / 18);
  }
  .section3 h2{
    @include res(font-size,32px, 18 / 32);
  }
  .section3 h3{
    @include res(font-size,16px, 14 / 16);
  }
  .section4 .text h2{
    @include res(font-size, 42px, 20 / 42);
  }
  .section5 .product .text h2{
    margin-bottom: 20px;
    @include res(font-size, 32px,16 / 32);
  }
  .section6 .pic img{
    max-height: 650px;
  }
  .section6 .text p{
    padding-bottom: 20px;
    @include res(margin-bottom, 17px,(lg:20px,mmd:10px));
    @include res(font-size,16px , 14 / 16);
  }
  .section6 .text .textptwo{
    @include res(font-size,18px , 16 / 18);
    font-weight: 600;
    padding: 0;
  }
  .section6 .text h2{
    @include res(font-size, 30px, 20 / 30);
    @include res(margin-bottom, 24px,(lg:20px,mmd:10px));
  }
  .section6 .text .texthtwo{
    @include res(font-size, 22px, 18 / 22);
    @include res(margin-bottom, 24px,(lg:20px,mmd:10px));
  }
}

.page_list_shop{
  .list-shop-banner h1{
    @include res(font-size,42px, 26 / 42);
  }
  .list-shop-banner h2{
    @include res(font-size,16px, 14 / 16);
  }
  .list-shop-nav ul li a{
    @include res(font-size,16px,14 / 16);
  }
  .list-shop .shop-num{
    @include res(font-size,16px, 14 / 16);
  }
}