@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
img {
  display: block;
}
*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  //文字中空描边
  // color: transparent;
  // -webkit-text-stroke: 1px #fff;
  // -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'roboto', '微软雅黑';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}
.comp-root{
  @include res(padding-top,$header-height-base, $header-height-ratio);
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1200px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 1330px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}


//圆形进度条
@keyframes progress {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress1 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress2 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes rain {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }

  25% {
    opacity: 0.7;
    transform: translateY(-10%);
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  75% {
    opacity: 0.7;
    transform: translateY(10%);
  }

  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}

// 圆圈扩散效果
@keyframes anim {

  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }

}

// 列表占位图片
.zw-img{
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
}
// 列表缩略图
.real-img{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all .3s;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.pages{
  display: flex;
  align-items: center;
  justify-content: center;
  @include res(margin-top,30px, 15 / 30);
  @include res(margin-bottom,30px, 15 / 30);
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-main;
    transition: all .3s;
    border: 2px solid $color-main;
    @include res(width,36px, 30 / 36);
    @include res(height,36px, 30 / 36);
    @include res(margin,5px, 3 / 5);
    &:hover,&.active{
      background-color: $color-main;
      color: #fff;
    }
  }
}

// 产品列表
.list-shop{
    .list{
        ul{
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            li{
                text-align: center;
                @include res(width,26%,(xs:45%));
                @include res(margin-bottom,60px, 20 / 60);
                .item-img{
                    position: relative;
                    max-width: 296px;
                    margin: 0 auto;
                    
                    @include res(margin-bottom,30px, 15 / 30);
                }
                .item-title{
                    transition: all .3s;
                    @include res(font-size,18px, 16 / 18);
                    @include res(margin-bottom,15px, 10 / 15);
                }
                .item-tag{
                    span{
                        background-color: $color-main-bg;
                        color: $color-desc;
                        font-size: 12px;
                        border-radius: 3px;
                        padding: 4px 6px;
                    }
                }
                &:hover{
                    .item-img{
                        .real-img{
                            transform: scale(1.05);
                        }
                    }
                    .item-title{
                        color: $color-main;
                    }
                }
            }
            .placeholder{
                @include res(width,26%,(xs:45%));
            }
        }
    }
}

// 线上线下门店title
.line-title{
  @include res(padding-left,65px, 10 / 65);
  @include res(padding-right,65px, 10 / 65);
  @include res(margin-bottom,85px, 20 / 85);
  h1{
    color: $color-main;
    border-bottom: solid $color-main;
    @include res(font-size,18px, 16 / 18);
    @include res(border-bottom-width,4px, 2 / 4);
    @include res(padding-top,50px, 20 / 50);
    @include res(padding-bottom,30px, 15 / 30);
  }
}