@import "../../assets/styles/utils";
footer {
    position: relative;
    box-sizing: border-box;
    @include res(padding-top, 100px, 40 / 100);
    @include res(padding-left,65px, 10 / 65);
    @include res(padding-right, 65px, 10 / 65);
    width: 100%;
    background-color: #a89cba;
    .top {
        display: flex;
        justify-content: space-between;
        @include res(flex-wrap, nowrap, (sm:wrap));
        .left {
            @include res(width,48.6%,(sm:100%));
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    @include res(width,50%,(xs:100%));
                    display: flex;
                    align-items: center;
                    @include res(margin-bottom, 60px, 20 / 60);
                    .pic {
                        @include res(width, 77px, 40 / 77);
                        @include res(height, 77px, 40 / 77);
                        background: rgba(255,255,255,.1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin-right: 25px;
                        flex-shrink: 0;
                        i {
                            color: #fff;

                        }
                    }
                    .text {
                        h3 {
                            color: #fff;
                            font-family: 'Roboto-Medium';
                            @include res(font-size,20px, 16 / 20);
                            margin-bottom: 10px;

                        }
                        p {
                            color: rgba($color: #fffefe, $alpha: .8);
                            @include res(font-size, 16px, 14 / 16);
                        }
                        a {
                            display: block;
                            position: relative;
                            color: rgba($color: #fffefe, $alpha: .8);
                            @include res(font-size, 16px, 14 / 16);
                            &:after {
                                content: "";
                                display: block;
                                width: 0;
                                height: 1px;
                                background-color: rgba($color: #fffefe, $alpha: .8);
                                position: absolute;
                                bottom: -6px;
                                left: 0;
                                transition: all .3s;
                            }
                            &:hover {
                                &:after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    &:nth-child(1) {
                        .pic {
                            i {
                                @include res(font-size, 40px, );
                            }
                        }
                    }
                    &:nth-child(2) {
                        .pic {
                            i {
                                @include res(font-size, 55px, );
                            }
                        }
                    }
                    &:nth-child(3) {
                        .pic {
                            i {
                                @include res(font-size, 24px,);
                            }
                        }
                    }
                    &:nth-child(4) {
                        .pic {
                            i {
                                @include res(font-size, 27px,);
                            }
                        }
                    }
                }
            }
        }
        .right {
            @include res(width,43%,(sm:100%));
            ul{
                align-items: stretch;
                justify-content: space-between;
                flex-wrap: wrap;
                @include res(display,flex);
            }
            li {
                @include res(width, 46%,(xs:100%));
                @include res(height,66px, 45 / 66);
                @include res(line-height, 66px, 45 / 66);
                border-bottom: 1px solid rgba($color: #ffffff, $alpha: .2);
                transition: all .3s;
                a {
                    span {
                        transition: all .3s;
                    }
                    color: #fff;
                    font-family: 'Roboto-Medium';
                    @include res(font-size,24px, 18 / 24);
                    color: #fffefe;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    i {
                        @include res(font-size,30px, 24 / 30);
                        opacity: 0;
                        transition: all .3s;
                    }
                }
                &:first-child {
                    border-top: 1px solid rgba($color: #ffffff, $alpha: .2);
                }
                &:nth-child(2) {
                    @include res(border-top,1px solid rgba($color: #ffffff, $alpha: .2),(xs:none));
                }
                &:hover {
                    border-bottom-color: #fff;
                    color: #fff;
                    a {
                        span {
                            transform: translateX(10px);
                        }
                        i {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .center {
        @include res(margin-top,85px,30 / 85);
        display: flex;
        justify-content: space-between;
        @include res(flex-wrap, nowrap, (sm:wrap));
        .left {
            @include res(width,48.6%,(sm:100%));
            h3 {
                @include res(font-size,16px, 14 / 16);
                color: #fffefe;
                margin-bottom: 30px;
            }
            p {
                @include res(font-size, 18px, 16 / 18);
                color: #fffefe;
                line-height: 1.6;
            }
        }
        .right {
            @include res(width,43%,(sm:100%));
            h3 {
                @include res(font-size, 16px, 14 / 16);
                color: #fffefe;
                margin-bottom: 30px;
            }
            .icons {
                display: flex;
                align-items: center;
                @include res(justify-content,null,(xs:center));
                a {
                    @include res(margin-right,35px, 10 / 35);
                    position: relative;
                    i {
                        color: #fff;
                    }
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: all .3s;
                        width: 100%;
                        height: auto;
                        &.img-w {
                            opacity: 1;
                        }
                        &.img {
                            opacity: 0;
                        }
                        &:hover {
                            &.img-w {
                                opacity: 0;
                            }
                            &.img {
                                opacity: 1;
                            }
                        }
                    }
                    &:nth-child(1) {
                        i {
                            @include res(font-size,35px, (xs:22px));
                            transition: all .3s;
                        }
                        &:hover {
                            i {
                                color: #39579A;
                            }
                        }
                    }
                    &:nth-child(2) {
                        i {
                            @include res(font-size, 38px, (xs:26px));
                            transition: all .3s;
                        }
                        &:hover {
                            i {
                                color: #55ACEE;
                            }
                        }
                    }
                    &:nth-child(3) {
                        @include res(width,124px,(xs:100px));
                        @include res(height,26px,(xs:21px));
                    }
                    &:nth-child(4) {
                        width: 85px;
                        height: 30px;
                        margin-right: 0;
                        @include res(width,85px,(xs:60px));
                        @include res(height,30px,(xs:21px));
                    }
                }
            }
        }
    }
    .bottom {
        box-sizing: border-box;
        position: relative;
        @include res(height,110px,(sm:40px,xs:auto));
        width: 100%;
        display: flex;
        @include res(margin-top,62px,20 / 62);
        justify-content: space-between;
        @include res(flex-wrap, nowrap, (sm:wrap));
        align-items: center;
        .left {
            position: relative;
            p {
                font-size: 14px;
                color: #fffefe;
            }
        }
        .right {
            font-size: 14px;
            color: #fffefe;
            span {
                margin-left: 5px;
            }
        }
    }
}
@media screen and (max-width:530px) {
    footer .bottom .left {
        width: 100%;
        text-align: center;
    }
    footer .bottom .right {
        width: 100%;
        text-align: center;
    }
}