header .left a {
  font-size: 16px;
}

@media (max-width: 1600px) {
  header .left a {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  header .left a {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  header .left a {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  header .left a {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  header .left a {
    font-size: 14px;
  }
}

header .right a {
  font-size: 15px;
}

@media (max-width: 1600px) {
  header .right a {
    font-size: 14.8px;
  }
}

@media (max-width: 1366px) {
  header .right a {
    font-size: 14.6px;
  }
}

@media (max-width: 1024px) {
  header .right a {
    font-size: 14.4px;
  }
}

@media (max-width: 991px) {
  header .right a {
    font-size: 14.2px;
  }
}

@media (max-width: 767px) {
  header .right a {
    font-size: 14px;
  }
}

header .nav li h3 a {
  font-size: 16px;
}

@media (max-width: 1600px) {
  header .nav li h3 a {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  header .nav li h3 a {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  header .nav li h3 a {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  header .nav li h3 a {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  header .nav li h3 a {
    font-size: 14px;
  }
}

header .logo img {
  width: 90%;
}

@media (max-width: 991px) {
  header .logo img {
    width: 70%;
  }
}

footer .top .left ul li .text h3 {
  font-size: 16px;
}

@media (max-width: 1600px) {
  footer .top .left ul li .text h3 {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  footer .top .left ul li .text h3 {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  footer .top .left ul li .text h3 {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  footer .top .left ul li .text h3 {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  footer .top .left ul li .text h3 {
    font-size: 14px;
  }
}

footer .top .left ul li .text p {
  font-size: 15px;
}

@media (max-width: 1600px) {
  footer .top .left ul li .text p {
    font-size: 14.8px;
  }
}

@media (max-width: 1366px) {
  footer .top .left ul li .text p {
    font-size: 14.6px;
  }
}

@media (max-width: 1024px) {
  footer .top .left ul li .text p {
    font-size: 14.4px;
  }
}

@media (max-width: 991px) {
  footer .top .left ul li .text p {
    font-size: 14.2px;
  }
}

@media (max-width: 767px) {
  footer .top .left ul li .text p {
    font-size: 14px;
  }
}

footer .top .right li a {
  font-size: 18px;
}

@media (max-width: 1600px) {
  footer .top .right li a {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  footer .top .right li a {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  footer .top .right li a {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  footer .top .right li a {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  footer .top .right li a {
    font-size: 16px;
  }
}

footer .top .right li a i {
  font-size: 26px;
}

@media (max-width: 1600px) {
  footer .top .right li a i {
    font-size: 24.8px;
  }
}

@media (max-width: 1366px) {
  footer .top .right li a i {
    font-size: 23.6px;
  }
}

@media (max-width: 1024px) {
  footer .top .right li a i {
    font-size: 22.4px;
  }
}

@media (max-width: 991px) {
  footer .top .right li a i {
    font-size: 21.2px;
  }
}

@media (max-width: 767px) {
  footer .top .right li a i {
    font-size: 20px;
  }
}

footer .center .left h3 {
  margin-bottom: 10px;
}

footer .center .left p {
  font-size: 15px;
}

@media (max-width: 1600px) {
  footer .center .left p {
    font-size: 14.8px;
  }
}

@media (max-width: 1366px) {
  footer .center .left p {
    font-size: 14.6px;
  }
}

@media (max-width: 1024px) {
  footer .center .left p {
    font-size: 14.4px;
  }
}

@media (max-width: 991px) {
  footer .center .left p {
    font-size: 14.2px;
  }
}

@media (max-width: 767px) {
  footer .center .left p {
    font-size: 14px;
  }
}

.list-shop .list ul li .item-title {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .list-shop .list ul li .item-title {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .list-shop .list ul li .item-title {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .list-shop .list ul li .item-title {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .list-shop .list ul li .item-title {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .list-shop .list ul li .item-title {
    font-size: 14px;
  }
}

.page_about_contact .contact .cont .left-cont .cont2 {
  font-size: 42px;
}

@media (max-width: 1600px) {
  .page_about_contact .contact .cont .left-cont .cont2 {
    font-size: 38px;
  }
}

@media (max-width: 1366px) {
  .page_about_contact .contact .cont .left-cont .cont2 {
    font-size: 34px;
  }
}

@media (max-width: 1024px) {
  .page_about_contact .contact .cont .left-cont .cont2 {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .page_about_contact .contact .cont .left-cont .cont2 {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .page_about_contact .contact .cont .left-cont .cont2 {
    font-size: 22px;
  }
}

.page_about_contact .contact .cont .left-cont .cont3 h1 {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_about_contact .contact .cont .left-cont .cont3 h1 {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_about_contact .contact .cont .left-cont .cont3 h1 {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_about_contact .contact .cont .left-cont .cont3 h1 {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_about_contact .contact .cont .left-cont .cont3 h1 {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_about_contact .contact .cont .left-cont .cont3 h1 {
    font-size: 14px;
  }
}

.page_about_contact .contact .cont .left-cont .cont3 .form .form-item input, .page_about_contact .contact .cont .left-cont .cont3 .form .form-item textarea {
  font-size: 14px;
}

.page_about_contact .contact .cont .left-cont .cont3 .form .form-item button {
  padding: 8px 26px;
  font-size: 14px;
}

@media (max-width: 1600px) {
  .page_about_contact .contact .cont .left-cont .cont3 .form .form-item button {
    font-size: 14px;
  }
}

@media (max-width: 1366px) {
  .page_about_contact .contact .cont .left-cont .cont3 .form .form-item button {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .page_about_contact .contact .cont .left-cont .cont3 .form .form-item button {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .page_about_contact .contact .cont .left-cont .cont3 .form .form-item button {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .page_about_contact .contact .cont .left-cont .cont3 .form .form-item button {
    font-size: 14px;
  }
}

.page_about_efficacious .efficacious .left-text h1 {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_about_efficacious .efficacious .left-text h1 {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_about_efficacious .efficacious .left-text h1 {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_about_efficacious .efficacious .left-text h1 {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_about_efficacious .efficacious .left-text h1 {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_about_efficacious .efficacious .left-text h1 {
    font-size: 14px;
  }
}

.page_about_efficacious .efficacious .left-text .desc {
  line-height: 1.5;
  font-size: 55px;
}

@media (max-width: 1600px) {
  .page_about_efficacious .efficacious .left-text .desc {
    font-size: 48.4px;
  }
}

@media (max-width: 1366px) {
  .page_about_efficacious .efficacious .left-text .desc {
    font-size: 41.8px;
  }
}

@media (max-width: 1024px) {
  .page_about_efficacious .efficacious .left-text .desc {
    font-size: 35.2px;
  }
}

@media (max-width: 991px) {
  .page_about_efficacious .efficacious .left-text .desc {
    font-size: 28.6px;
  }
}

@media (max-width: 767px) {
  .page_about_efficacious .efficacious .left-text .desc {
    font-size: 22px;
  }
}

.page_about_efficacious .efficacious .right-list ul li h3 {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_about_efficacious .efficacious .right-list ul li h3 {
    font-size: 16px;
  }
}

@media (max-width: 1366px) {
  .page_about_efficacious .efficacious .right-list ul li h3 {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .page_about_efficacious .efficacious .right-list ul li h3 {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .page_about_efficacious .efficacious .right-list ul li h3 {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .page_about_efficacious .efficacious .right-list ul li h3 {
    font-size: 16px;
  }
}

.page_about_mission .section1 .cont .right-text .title {
  font-size: 30px;
}

@media (max-width: 1600px) {
  .page_about_mission .section1 .cont .right-text .title {
    font-size: 27.6px;
  }
}

@media (max-width: 1366px) {
  .page_about_mission .section1 .cont .right-text .title {
    font-size: 25.2px;
  }
}

@media (max-width: 1024px) {
  .page_about_mission .section1 .cont .right-text .title {
    font-size: 22.8px;
  }
}

@media (max-width: 991px) {
  .page_about_mission .section1 .cont .right-text .title {
    font-size: 20.4px;
  }
}

@media (max-width: 767px) {
  .page_about_mission .section1 .cont .right-text .title {
    font-size: 18px;
  }
}

.page_about_mission .section1 .cont .right-text .desc {
  font-size: 18px;
}

@media (max-width: 1600px) {
  .page_about_mission .section1 .cont .right-text .desc {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .page_about_mission .section1 .cont .right-text .desc {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .page_about_mission .section1 .cont .right-text .desc {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .page_about_mission .section1 .cont .right-text .desc {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .page_about_mission .section1 .cont .right-text .desc {
    font-size: 14px;
  }
}

.page_about_mission .section2 h1 {
  font-size: 42px;
}

@media (max-width: 1600px) {
  .page_about_mission .section2 h1 {
    font-size: 38px;
  }
}

@media (max-width: 1366px) {
  .page_about_mission .section2 h1 {
    font-size: 34px;
  }
}

@media (max-width: 1024px) {
  .page_about_mission .section2 h1 {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .page_about_mission .section2 h1 {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .page_about_mission .section2 h1 {
    font-size: 22px;
  }
}

.page_about_mission .section2 .desc {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_about_mission .section2 .desc {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_about_mission .section2 .desc {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_about_mission .section2 .desc {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_about_mission .section2 .desc {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_about_mission .section2 .desc {
    font-size: 14px;
  }
}

.page_about_offline .offline .cont .left h2 {
  font-size: 24px;
}

@media (max-width: 1600px) {
  .page_about_offline .offline .cont .left h2 {
    font-size: 22.4px;
  }
}

@media (max-width: 1366px) {
  .page_about_offline .offline .cont .left h2 {
    font-size: 20.8px;
  }
}

@media (max-width: 1024px) {
  .page_about_offline .offline .cont .left h2 {
    font-size: 19.2px;
  }
}

@media (max-width: 991px) {
  .page_about_offline .offline .cont .left h2 {
    font-size: 17.6px;
  }
}

@media (max-width: 767px) {
  .page_about_offline .offline .cont .left h2 {
    font-size: 16px;
  }
}

.page_about_offline .offline .cont.cont1 .left .desc p {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_about_offline .offline .cont.cont1 .left .desc p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_about_offline .offline .cont.cont1 .left .desc p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_about_offline .offline .cont.cont1 .left .desc p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_about_offline .offline .cont.cont1 .left .desc p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_about_offline .offline .cont.cont1 .left .desc p {
    font-size: 14px;
  }
}

.page_about_offline .offline .cont.cont2 .left .desc p {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_about_offline .offline .cont.cont2 .left .desc p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_about_offline .offline .cont.cont2 .left .desc p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_about_offline .offline .cont.cont2 .left .desc p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_about_offline .offline .cont.cont2 .left .desc p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_about_offline .offline .cont.cont2 .left .desc p {
    font-size: 14px;
  }
}

.page_about_offline .offline .cont.cont2 .left .desc p span {
  font-size: 14px;
}

@media (max-width: 1600px) {
  .page_about_offline .offline .cont.cont2 .left .desc p span {
    font-size: 14px;
  }
}

@media (max-width: 1366px) {
  .page_about_offline .offline .cont.cont2 .left .desc p span {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .page_about_offline .offline .cont.cont2 .left .desc p span {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .page_about_offline .offline .cont.cont2 .left .desc p span {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .page_about_offline .offline .cont.cont2 .left .desc p span {
    font-size: 14px;
  }
}

.page_about_reviews .section2 .section2-title {
  font-size: 18px;
}

@media (max-width: 1600px) {
  .page_about_reviews .section2 .section2-title {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .page_about_reviews .section2 .section2-title {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .page_about_reviews .section2 .section2-title {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .page_about_reviews .section2 .section2-title {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .page_about_reviews .section2 .section2-title {
    font-size: 16px;
  }
}

.page_about_story .story .container .cont .desc {
  font-size: 42px;
}

@media (max-width: 1600px) {
  .page_about_story .story .container .cont .desc {
    font-size: 38px;
  }
}

@media (max-width: 1366px) {
  .page_about_story .story .container .cont .desc {
    font-size: 34px;
  }
}

@media (max-width: 1024px) {
  .page_about_story .story .container .cont .desc {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .page_about_story .story .container .cont .desc {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .page_about_story .story .container .cont .desc {
    font-size: 22px;
  }
}

.page_about_story .story .container .cont .text .left, .page_about_story .story .container .cont .text .right {
  font-size: 16px;
  width: 45%;
}

@media (max-width: 1600px) {
  .page_about_story .story .container .cont .text .left, .page_about_story .story .container .cont .text .right {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_about_story .story .container .cont .text .left, .page_about_story .story .container .cont .text .right {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_about_story .story .container .cont .text .left, .page_about_story .story .container .cont .text .right {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_about_story .story .container .cont .text .left, .page_about_story .story .container .cont .text .right {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_about_story .story .container .cont .text .left, .page_about_story .story .container .cont .text .right {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .page_about_story .story .container .cont .text .left, .page_about_story .story .container .cont .text .right {
    width: 100%;
  }
}

.page_about_story .story .container .cont .text .right .t2 {
  font-size: 18px;
}

@media (max-width: 1600px) {
  .page_about_story .story .container .cont .text .right .t2 {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .page_about_story .story .container .cont .text .right .t2 {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .page_about_story .story .container .cont .text .right .t2 {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .page_about_story .story .container .cont .text .right .t2 {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .page_about_story .story .container .cont .text .right .t2 {
    font-size: 16px;
  }
}

.page_about_sustainability .section1 .container .t1 {
  font-size: 16px;
  margin-bottom: 0px;
}

@media (max-width: 1600px) {
  .page_about_sustainability .section1 .container .t1 {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_about_sustainability .section1 .container .t1 {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_about_sustainability .section1 .container .t1 {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_about_sustainability .section1 .container .t1 {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_about_sustainability .section1 .container .t1 {
    font-size: 14px;
  }
}

.page_about_sustainability .section1 .container .t2 .t {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_about_sustainability .section1 .container .t2 .t {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_about_sustainability .section1 .container .t2 .t {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_about_sustainability .section1 .container .t2 .t {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_about_sustainability .section1 .container .t2 .t {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_about_sustainability .section1 .container .t2 .t {
    font-size: 14px;
  }
}

.page_about_sustainability .section2 {
  color: #333333;
}

.page_about_sustainability .section2 .section2_lefts .title {
  font-size: 24px;
}

@media (max-width: 1600px) {
  .page_about_sustainability .section2 .section2_lefts .title {
    font-size: 22.4px;
  }
}

@media (max-width: 1366px) {
  .page_about_sustainability .section2 .section2_lefts .title {
    font-size: 20.8px;
  }
}

@media (max-width: 1024px) {
  .page_about_sustainability .section2 .section2_lefts .title {
    font-size: 19.2px;
  }
}

@media (max-width: 991px) {
  .page_about_sustainability .section2 .section2_lefts .title {
    font-size: 17.6px;
  }
}

@media (max-width: 767px) {
  .page_about_sustainability .section2 .section2_lefts .title {
    font-size: 16px;
  }
}

.page_about_sustainability .section2 .t {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_about_sustainability .section2 .t {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_about_sustainability .section2 .t {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_about_sustainability .section2 .t {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_about_sustainability .section2 .t {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_about_sustainability .section2 .t {
    font-size: 14px;
  }
}

.page_about_sustainability .section3 {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (max-width: 1600px) {
  .page_about_sustainability .section3 {
    padding-top: 18px;
  }
}

@media (max-width: 1366px) {
  .page_about_sustainability .section3 {
    padding-top: 16px;
  }
}

@media (max-width: 1024px) {
  .page_about_sustainability .section3 {
    padding-top: 14px;
  }
}

@media (max-width: 991px) {
  .page_about_sustainability .section3 {
    padding-top: 12px;
  }
}

@media (max-width: 767px) {
  .page_about_sustainability .section3 {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .page_about_sustainability .section3 {
    padding-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .page_about_sustainability .section3 {
    padding-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .page_about_sustainability .section3 {
    padding-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .page_about_sustainability .section3 {
    padding-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .page_about_sustainability .section3 {
    padding-bottom: 10px;
  }
}

.page_about_sustainability .section3 .cont .right .desc {
  color: #fff;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .page_about_sustainability .section3 .cont .right .desc {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .page_about_sustainability .section3 .cont .right .desc {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .page_about_sustainability .section3 .cont .right .desc {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .page_about_sustainability .section3 .cont .right .desc {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .page_about_sustainability .section3 .cont .right .desc {
    margin-bottom: 10px;
  }
}

.page_about_sustainability .section3 .cont .right .title {
  color: #fff;
  font-size: 24px;
}

@media (max-width: 1600px) {
  .page_about_sustainability .section3 .cont .right .title {
    font-size: 22.4px;
  }
}

@media (max-width: 1366px) {
  .page_about_sustainability .section3 .cont .right .title {
    font-size: 20.8px;
  }
}

@media (max-width: 1024px) {
  .page_about_sustainability .section3 .cont .right .title {
    font-size: 19.2px;
  }
}

@media (max-width: 991px) {
  .page_about_sustainability .section3 .cont .right .title {
    font-size: 17.6px;
  }
}

@media (max-width: 767px) {
  .page_about_sustainability .section3 .cont .right .title {
    font-size: 16px;
  }
}

.page_article_shop .section1 .shop-detail .right h1 {
  font-size: 32px;
}

@media (max-width: 1600px) {
  .page_article_shop .section1 .shop-detail .right h1 {
    font-size: 29.6px;
  }
}

@media (max-width: 1366px) {
  .page_article_shop .section1 .shop-detail .right h1 {
    font-size: 27.2px;
  }
}

@media (max-width: 1024px) {
  .page_article_shop .section1 .shop-detail .right h1 {
    font-size: 24.8px;
  }
}

@media (max-width: 991px) {
  .page_article_shop .section1 .shop-detail .right h1 {
    font-size: 22.4px;
  }
}

@media (max-width: 767px) {
  .page_article_shop .section1 .shop-detail .right h1 {
    font-size: 20px;
  }
}

.page_article_shop .section1 .shop-detail .right .detail .item h2 {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_article_shop .section1 .shop-detail .right .detail .item h2 {
    font-size: 16px;
  }
}

@media (max-width: 1366px) {
  .page_article_shop .section1 .shop-detail .right .detail .item h2 {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .page_article_shop .section1 .shop-detail .right .detail .item h2 {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .page_article_shop .section1 .shop-detail .right .detail .item h2 {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .page_article_shop .section1 .shop-detail .right .detail .item h2 {
    font-size: 16px;
  }
}

.page_article_shop .section1 .shop-detail .right .detail .item .desc {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_article_shop .section1 .shop-detail .right .detail .item .desc {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_article_shop .section1 .shop-detail .right .detail .item .desc {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_article_shop .section1 .shop-detail .right .detail .item .desc {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_article_shop .section1 .shop-detail .right .detail .item .desc {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_article_shop .section1 .shop-detail .right .detail .item .desc {
    font-size: 14px;
  }
}

.page_article_shop .section3 .title {
  font-size: 26px;
}

@media (max-width: 1600px) {
  .page_article_shop .section3 .title {
    font-size: 24.4px;
  }
}

@media (max-width: 1366px) {
  .page_article_shop .section3 .title {
    font-size: 22.8px;
  }
}

@media (max-width: 1024px) {
  .page_article_shop .section3 .title {
    font-size: 21.2px;
  }
}

@media (max-width: 991px) {
  .page_article_shop .section3 .title {
    font-size: 19.6px;
  }
}

@media (max-width: 767px) {
  .page_article_shop .section3 .title {
    font-size: 18px;
  }
}

.page_index .banner #banner-swiper .swiper-slide .title h2 {
  font-size: 42px;
}

@media (max-width: 1600px) {
  .page_index .banner #banner-swiper .swiper-slide .title h2 {
    font-size: 37.2px;
  }
}

@media (max-width: 1366px) {
  .page_index .banner #banner-swiper .swiper-slide .title h2 {
    font-size: 32.4px;
  }
}

@media (max-width: 1024px) {
  .page_index .banner #banner-swiper .swiper-slide .title h2 {
    font-size: 27.6px;
  }
}

@media (max-width: 991px) {
  .page_index .banner #banner-swiper .swiper-slide .title h2 {
    font-size: 22.8px;
  }
}

@media (max-width: 767px) {
  .page_index .banner #banner-swiper .swiper-slide .title h2 {
    font-size: 18px;
  }
}

.page_index .banner #banner-swiper .swiper-slide .title p {
  font-size: 18px;
}

@media (max-width: 1600px) {
  .page_index .banner #banner-swiper .swiper-slide .title p {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .page_index .banner #banner-swiper .swiper-slide .title p {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .page_index .banner #banner-swiper .swiper-slide .title p {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .page_index .banner #banner-swiper .swiper-slide .title p {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .page_index .banner #banner-swiper .swiper-slide .title p {
    font-size: 14px;
  }
}

.page_index .section3 h2 {
  font-size: 32px;
}

@media (max-width: 1600px) {
  .page_index .section3 h2 {
    font-size: 29.2px;
  }
}

@media (max-width: 1366px) {
  .page_index .section3 h2 {
    font-size: 26.4px;
  }
}

@media (max-width: 1024px) {
  .page_index .section3 h2 {
    font-size: 23.6px;
  }
}

@media (max-width: 991px) {
  .page_index .section3 h2 {
    font-size: 20.8px;
  }
}

@media (max-width: 767px) {
  .page_index .section3 h2 {
    font-size: 18px;
  }
}

.page_index .section3 h3 {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_index .section3 h3 {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_index .section3 h3 {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_index .section3 h3 {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_index .section3 h3 {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_index .section3 h3 {
    font-size: 14px;
  }
}

.page_index .section4 .text h2 {
  font-size: 42px;
}

@media (max-width: 1600px) {
  .page_index .section4 .text h2 {
    font-size: 37.6px;
  }
}

@media (max-width: 1366px) {
  .page_index .section4 .text h2 {
    font-size: 33.2px;
  }
}

@media (max-width: 1024px) {
  .page_index .section4 .text h2 {
    font-size: 28.8px;
  }
}

@media (max-width: 991px) {
  .page_index .section4 .text h2 {
    font-size: 24.4px;
  }
}

@media (max-width: 767px) {
  .page_index .section4 .text h2 {
    font-size: 20px;
  }
}

.page_index .section5 .product .text h2 {
  margin-bottom: 20px;
  font-size: 32px;
}

@media (max-width: 1600px) {
  .page_index .section5 .product .text h2 {
    font-size: 28.8px;
  }
}

@media (max-width: 1366px) {
  .page_index .section5 .product .text h2 {
    font-size: 25.6px;
  }
}

@media (max-width: 1024px) {
  .page_index .section5 .product .text h2 {
    font-size: 22.4px;
  }
}

@media (max-width: 991px) {
  .page_index .section5 .product .text h2 {
    font-size: 19.2px;
  }
}

@media (max-width: 767px) {
  .page_index .section5 .product .text h2 {
    font-size: 16px;
  }
}

.page_index .section6 .pic img {
  max-height: 650px;
}

.page_index .section6 .text p {
  padding-bottom: 20px;
  margin-bottom: 17px;
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_index .section6 .text p {
    margin-bottom: 20px;
  }
}

@media (max-width: 1366px) {
  .page_index .section6 .text p {
    margin-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .page_index .section6 .text p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_index .section6 .text p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_index .section6 .text p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_index .section6 .text p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_index .section6 .text p {
    font-size: 14px;
  }
}

.page_index .section6 .text .textptwo {
  font-size: 18px;
  font-weight: 600;
  padding: 0;
}

@media (max-width: 1600px) {
  .page_index .section6 .text .textptwo {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .page_index .section6 .text .textptwo {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .page_index .section6 .text .textptwo {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .page_index .section6 .text .textptwo {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .page_index .section6 .text .textptwo {
    font-size: 16px;
  }
}

.page_index .section6 .text h2 {
  font-size: 30px;
  margin-bottom: 24px;
}

@media (max-width: 1600px) {
  .page_index .section6 .text h2 {
    font-size: 28px;
  }
}

@media (max-width: 1366px) {
  .page_index .section6 .text h2 {
    font-size: 26px;
  }
}

@media (max-width: 1024px) {
  .page_index .section6 .text h2 {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .page_index .section6 .text h2 {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .page_index .section6 .text h2 {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .page_index .section6 .text h2 {
    margin-bottom: 20px;
  }
}

@media (max-width: 1366px) {
  .page_index .section6 .text h2 {
    margin-bottom: 10px;
  }
}

.page_index .section6 .text .texthtwo {
  font-size: 22px;
  margin-bottom: 24px;
}

@media (max-width: 1600px) {
  .page_index .section6 .text .texthtwo {
    font-size: 21.2px;
  }
}

@media (max-width: 1366px) {
  .page_index .section6 .text .texthtwo {
    font-size: 20.4px;
  }
}

@media (max-width: 1024px) {
  .page_index .section6 .text .texthtwo {
    font-size: 19.6px;
  }
}

@media (max-width: 991px) {
  .page_index .section6 .text .texthtwo {
    font-size: 18.8px;
  }
}

@media (max-width: 767px) {
  .page_index .section6 .text .texthtwo {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .page_index .section6 .text .texthtwo {
    margin-bottom: 20px;
  }
}

@media (max-width: 1366px) {
  .page_index .section6 .text .texthtwo {
    margin-bottom: 10px;
  }
}

.page_list_shop .list-shop-banner h1 {
  font-size: 42px;
}

@media (max-width: 1600px) {
  .page_list_shop .list-shop-banner h1 {
    font-size: 38.8px;
  }
}

@media (max-width: 1366px) {
  .page_list_shop .list-shop-banner h1 {
    font-size: 35.6px;
  }
}

@media (max-width: 1024px) {
  .page_list_shop .list-shop-banner h1 {
    font-size: 32.4px;
  }
}

@media (max-width: 991px) {
  .page_list_shop .list-shop-banner h1 {
    font-size: 29.2px;
  }
}

@media (max-width: 767px) {
  .page_list_shop .list-shop-banner h1 {
    font-size: 26px;
  }
}

.page_list_shop .list-shop-banner h2 {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_list_shop .list-shop-banner h2 {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_list_shop .list-shop-banner h2 {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_list_shop .list-shop-banner h2 {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_list_shop .list-shop-banner h2 {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_list_shop .list-shop-banner h2 {
    font-size: 14px;
  }
}

.page_list_shop .list-shop-nav ul li a {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_list_shop .list-shop-nav ul li a {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_list_shop .list-shop-nav ul li a {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_list_shop .list-shop-nav ul li a {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_list_shop .list-shop-nav ul li a {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_list_shop .list-shop-nav ul li a {
    font-size: 14px;
  }
}

.page_list_shop .list-shop .shop-num {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page_list_shop .list-shop .shop-num {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page_list_shop .list-shop .shop-num {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page_list_shop .list-shop .shop-num {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page_list_shop .list-shop .shop-num {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page_list_shop .list-shop .shop-num {
    font-size: 14px;
  }
}
